import { HttpParams } from '@angular/common/http';
import { GetLeadParams, LeadFilters, LeadQuickFilters } from '@core/models/interfaces/lead';
import moment from 'moment';

export function getParamsFilterLeads(
  filters: LeadFilters,
  quickFilters: LeadQuickFilters,
  keyword: string,
  pagination: { page: number; size: number },
  sorts: string
): GetLeadParams {
  const { page, size } = pagination;

  const { agents, states, source, subSource, currentUser, genders, csr, leadTags } = filters;
  const { leadStatus, birthday, dates, roles, update, dateAdded, leadTypes, leadStages } = quickFilters;

  const dateAddedFrom = dateAdded?.[0] ? moment(dateAdded[0]).startOf('date').toDate().toISOString() : undefined;
  const dateAddedTo = dateAdded?.[1] ? moment(dateAdded[1]).endOf('date').toDate().toISOString() : undefined;
  const birthdayFrom = birthday?.[0] ? moment(birthday[0]).startOf('date').toDate().toISOString() : undefined;
  const birthdayTo = birthday?.[1] ? moment(birthday[1]).endOf('date').toDate().toISOString() : undefined;

  const lastUpdateFrom =
    update === 'LAST_UPDATE' && dates?.[0] ? moment(dates[0]).startOf('date').toDate().toISOString() : undefined;
  const lastUpdateTo =
    update === 'LAST_UPDATE' && dates?.[1]
      ? moment(dates[1]).endOf('date').toDate().toISOString()
      : lastUpdateFrom
        ? moment().toDate().toISOString()
        : undefined;

  const noUpdateFrom =
    update === 'NO_UPDATE' && dates[0] ? moment(dates[0]).startOf('date').toDate().toISOString() : undefined;
  const noUpdateTo =
    update === 'NO_UPDATE' && dates?.[1]
      ? moment(dates[1]).endOf('date').toDate().toISOString()
      : noUpdateFrom
        ? moment().toDate().toISOString()
        : undefined;

  return {
    page,
    size,
    currentUserIds: currentUser.map(item => item.id),
    leadStatusIds: leadStatus ? [leadStatus] : undefined,
    agentIds: agents.map(item => item.id),
    states: states.map(item => item.name),
    leadSourceIds: source.map(item => item.id),
    subSourceIds: subSource.map(item => item.id),
    leadStageIds: leadStages.map(item => item.id),
    keyword: keyword,
    birthdayFrom,
    birthdayTo,
    lastUpdateFrom,
    lastUpdateTo,
    noUpdateFrom,
    noUpdateTo,
    from: dateAddedFrom,
    to: dateAddedTo,
    leadTypes: leadTypes.map(item => item.value),
    genders: genders.map(item => item.value),
    csrIds: csr.map(item => item.id),
    roles: roles,
    sorts: sorts || '-createdAt',
    leadTagIds: leadTags.map(item => item.id)
  };
}

export function parserParamsToAPILeads(params?: GetLeadParams) {
  let queryParams = new HttpParams();

  if (params?.keyword !== undefined) {
    queryParams = queryParams.append('keyword', params?.keyword);
  }
  if (params?.currentUserIds) {
    for (let id of params?.currentUserIds) {
      queryParams = queryParams.append('currentUserIds', id);
    }
  }
  if (params?.leadStatusIds) {
    for (let id of params?.leadStatusIds) {
      queryParams = queryParams.append('leadStatusIds', id);
    }
  }
  if (params?.states) {
    for (let id of params?.states) {
      queryParams = queryParams.append('states', id);
    }
  }
  if (params?.leadSourceIds) {
    for (let id of params?.leadSourceIds) {
      queryParams = queryParams.append('leadSourceIds', id);
    }
  }
  if (params?.subSourceIds) {
    for (let id of params?.subSourceIds) {
      queryParams = queryParams.append('subSourceIds', id);
    }
  }
  if (params?.agentIds) {
    for (let id of params?.agentIds) {
      queryParams = queryParams.append('agentIds', id);
    }
  }
  if (params?.from !== undefined) {
    queryParams = queryParams.append('from', params?.from);
  }
  if (params?.to !== undefined) {
    queryParams = queryParams.append('to', params?.to);
  }
  if (params?.page !== undefined) {
    queryParams = queryParams.append('page', params?.page);
  }
  if (params?.size !== undefined) {
    queryParams = queryParams.append('size', params?.size);
  }
  if (params?.sorts !== undefined) {
    queryParams = queryParams.append('sorts', params?.sorts);
  }
  //BIRTHDAY
  if (params?.birthdayFrom !== undefined) {
    queryParams = queryParams.append('birthdayFrom', params?.birthdayFrom);
  }
  if (params?.birthdayTo !== undefined) {
    queryParams = queryParams.append('birthdayTo', params?.birthdayTo);
  }
  // LAST UPDATE
  if (params?.lastUpdateFrom !== undefined) {
    queryParams = queryParams.append('lastUpdateFrom', params?.lastUpdateFrom);
  }
  if (params?.lastUpdateTo !== undefined) {
    queryParams = queryParams.append('lastUpdateTo', params?.lastUpdateTo);
  }
  // NO UPDATE
  if (params?.noUpdateFrom !== undefined) {
    queryParams = queryParams.append('noUpdateFrom', params?.noUpdateFrom);
  }
  if (params?.noUpdateTo !== undefined) {
    queryParams = queryParams.append('noUpdateTo', params?.noUpdateTo);
  }
  if (params?.leadTypes) {
    for (let id of params?.leadTypes) {
      queryParams = queryParams.append('leadTypes', id);
    }
  }
  if (params?.genders) {
    for (let id of params?.genders) {
      queryParams = queryParams.append('genders', id);
    }
  }
  if (params?.csrIds) {
    for (let id of params?.csrIds) {
      queryParams = queryParams.append('csrIds', id);
    }
  }
  if (params?.roles) {
    queryParams = queryParams.append('roles', params?.roles);
  }

  if (params?.leadStageIds) {
    for (let id of params?.leadStageIds) {
      queryParams = queryParams.append('leadStageIds', id);
    }
  }

  if (params?.leadTagIds) {
    for (let id of params?.leadTagIds) {
      queryParams = queryParams.append('leadTagIds', id);
    }
  }

  //ACTION
  if (params?.actionChange) {
    queryParams = queryParams.append('actionChange', params.actionChange);
  }

  if (params?.attributeId) {
    queryParams = queryParams.append('attributeId', params.attributeId);
  }

  return queryParams;
}
